import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

function SEO({ description, lang, meta, title }) {
  const defaultTitle = 'Kinloch Golf Club';
  const metaDescription = 'Dedicated to providing members and guests a premier golf experience with world class course conditions and a staff committed to exemplary services offered in a genuine and comfortable atmosphere, The Kinloch Experience is the best of southern hospitality. Recognized as one of the unique golf experiences in the country, Kinloch features an outstanding Lester George and Vinny Giles parkland style 18-hole layout, a knowledgeable and experienced caddie staff along with comfortable and convenient cottages for overnight accommodations. Resident and National memberships are by invitation only.';
  const author = 'Kinloch';

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: `description`,
          content: description || metaDescription,
        },
        {
          property: `og:title`,
          content: title || defaultTitle,
        },
        {
          property: `og:description`,
          content: description || metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: author || `Kinloch`,
        },
        {
          name: `twitter:title`,
          content: title || defaultTitle,
        },
        {
          name: `twitter:description`,
          content: description || metaDescription,
        },
      ].concat(meta)}
    />
  )
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
  title: `Kinloch Golf Club`,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO